@import "../../assets/theme/variable.scss";

.prdct_listing_crd {
    position: relative;
    cursor: pointer;
    width: 48%;
    padding: 14px 10px;
    box-shadow: 0px 2px 16px #00000014;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
  
    @media (max-width: 767px) {
      width: 100%;
    }
  
    .right_arrw {
      position: absolute;
      right: 14px;
      top: 38%;
    }
  
    .lst_crd_lft {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
  
      figure {
        width: 90px;
        height: 80px;
        margin-right: 10px;
  
        img {
          object-fit: cover;
          border-radius: 6px;
        }
      }
  
      .lst_dtl {
        h6 {
          color: #5d5d5d;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          margin-top: -6px;
  
          figure {
            height: 30px;
            width: 30px;
  
            img {
              object-fit: contain;
            }
          }
        }
  
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: $c_black;
        }
  
        p {
          font-size: 12px !important;
          font-weight: 300 !important;
          color: $gray !important;
          padding: 4px 0;
          padding-top: 0 !important;
        }
  
        h3 {
          color: $secondary;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  
    .lst_crd_ryt {
      display: flex;
      align-items: flex-end;
  
      figure {
        height: 22px;
        width: 22px;
  
        img {
          object-fit: contain;
        }
      }
    }
  }