//pagination
@import "../../assets/theme/variable.scss";
.pagination {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    h3 {
      width: 100%;
      min-height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "regular";
      font-size: 18px;
    }
  
    p {
      font-size: 14px;
  
      span {
        font-family: "bold";
        color: black;
      }
    }
  
    .pages {
      display: flex;
      .pages_inner {
        display: flex;
      }
      @media (max-width: 350px) {
        flex-direction: column-reverse;
        .pages_inner {
          display: flex;
          margin-bottom: 20px;
        }
      }
  
      .select_pgntn {
        margin-right: 10px;
        & > div {
          padding: 3.5px 12px !important;
        }
        svg {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
      .prevbtn {
        background: no-repeat;
        border: none;
      }
  
      svg {
        font-size: 26px;
        border: 0.5px solid #a5a5a5;
        padding: 6px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-right: 10px;
        cursor: pointer;
        color: black;
      }
  
      .count {
        display: flex;
        align-items: center;
        margin-right: 10px;
        background: none;
        /* padding: 6px; */
        border-radius: 24px;
        border: 0.5px solid #fff;
  
        .actv {
          background: $secondary;
  
          color: white;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          cursor: pointer;
          border: none;
        }
  
        .inActv {
          background: transparent;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          color: black;
          cursor: pointer;
          border: none;
        }
      }
    }
  }