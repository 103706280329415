@import "../../assets/theme/variable.scss";
.fallback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // z-index: 9999;
    figure {
      height: 150px;
      width: 150px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  