@import "../../assets/theme/variable.scss";
.prdct_crd_sk {
    padding: 14px;
    width: 18%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    margin: 0 5px;
  
    @media (max-width: 1200px) {
      width: 18%;
    }
  
    @media (max-width: 992px) {
      width: 31%;
      margin-top: 15px;
    }
  
    @media (max-width: 767px) {
      width: 45%;
    }
  
    @media (max-width: 575px) {
      width: 47%;
      margin: 0;
      margin-top: 10px;
    }
  
    .prdct_img {
      position: relative;
      width: 100%;
      height: 190px;
  
      @media (max-width: 600px) {
        height: 145px;
      }
  
      img {
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }