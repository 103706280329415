@import "../../assets/theme/variable.scss";
.prdct_crd {
    cursor: pointer;
    padding: 14px;
    width: calc(25% - 7.5px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    margin: 0 5px;
  
    @media (max-width: 1200px) {
      width: 18%;
    }
  
    @media (max-width: 992px) {
      width: 31%;
      margin-top: 15px;
    }
  
    @media (max-width: 767px) {
      width: 48%;
    }
  
    @media (max-width: 600px) {
      width: 47%;
      padding: 10px;
    }
  
    .prdct_img {
      position: relative;
      width: 100%;
      height: 190px;
      .zoom_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: none;
        transition: 0.3s all;
        align-items: center;
        justify-content: center;
        svg {
          color: $secondary;
        }
      }
      @media (max-width: 600px) {
        height: 145px;
      }
      span {
        height: 100% !important;
        width: 100% !important;
        img {
          object-fit: cover;
          border-radius: 10px;
        }
      }
  
      .wkly_dls {
        position: absolute;
        bottom: 0;
        z-index: 2;
        height: 25px;
        width: 80px;
        background: $secondary;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        h6 {
          color: $white;
          font-size: 10px;
          font-weight: 500;
        }
      }
      .boosterIcon {
        position: absolute;
        top: 8px;
        left: 8px;
        background: white;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
  
        svg {
          color: $secondary;
          font-size: 18px;
        }
      }
      .fvrt_img {
        position: absolute;
        top: 8px;
        right: 8px;
        background: white;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
  
        svg {
          color: $secondary;
          font-size: 18px;
        }
      }
      &::before {
        background: #0000004d;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 12px;
        display: none;
        transition: 0.3s all;
      }
    }
    &:hover {
      .prdct_img::before {
        display: block;
        transition: 0.3s all;
        @media (max-width: 600px) {
          display: none;
        }
      }
      .zoom_icon {
        display: flex;
        transition: 0.3s all;
      }
    }
    .prdct_dtl {
      padding-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
  
      h4 {
        font-size: 12px;
        font-weight: 500;
        color: $secondary;
  
        @media (max-width: 767px) {
          font-size: 11px;
        }
        @media (max-width: 600px) {
          font-size: 10px;
        }
  
        &:last-child {
          color: $gray;
          font-weight: 300;
          padding-bottom: 6px;
          text-transform: uppercase;
        }
      }
    }
  
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-block: 5px;
      word-break: break-all;
      overflow: hidden;
      color: $c_black;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
  
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  
    p {
      color: $gray;
      font-size: 13px;
      font-weight: 300;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .prodcts_sc {
    padding: 10px 0 0;
  
    .prdct_hdngs {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      @media (max-width: 480px) {
        padding: 20px 0;
      }
  
      h2 {
        font-size: 22px;
        font-weight: 500;
        color: $secondary;
  
        @media (max-width: 992px) {
          font-size: 22px;
        }
  
        @media (max-width: 480px) {
          font-size: 20px;
        }
  
        @media (max-width: 420px) {
          font-size: 20px;
        }
      }
  
      p {
        font-size: 16px;
        font-weight: 400;
        color: $secondary;
        border-bottom: 1px solid $secondary;
        cursor: pointer;
      }
  
      @media (max-width: 420px) {
        font-size: 14px;
      }
    }
  
    .crds_sec {
      display: flex;
      gap: 10px;
      align-items: normal;
      flex-wrap: wrap;
  
      @media (max-width: 1200px) {
        gap: 2%;
      }
  
      @media (max-width: 767px) {
        gap: 4%;
      }
    }
  }
  