@import "../../assets/theme/variable.scss";
.gallery_modal {
    .modalBox_body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 15px;
      width: 100%;
      background: transparent;
      position: relative;
      z-index: 1;
      min-height: 100%;
  
      > .MuiBox-root {
        width: 100%;
        max-width: 570px;
        padding: 35px 20px 0 20px;
        box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08),
          0px 6px 8px -6px rgba(45, 115, 227, 0.12);
        border-radius: 30px;
        background: #fff;
        position: relative;
        margin: 0 auto;
      }
  
      @media (min-width: 767px) {
        > .MuiBox-root {
          max-width: 60vw;
        }
      }
  
      .close_btn {
        top: 10px;
        right: 10px;
        position: absolute;
        color: black !important;
        z-index: 999 !important;
        cursor: pointer;
        min-width: 1px;
        width: auto;
        padding: 0;
        color: #fff;
        transition: all 0.3s ease-in-out 0s;
  
        &:hover {
          color: $primary;
        }
      }
  
      .gallery_swiper {
        .swiper-wrapper {
          margin-bottom: 0;
        }
  
        .swiper_action {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          gap: 15px;
  
          :is(.swiper-button-next, .swiper-button-prev) {
            position: initial;
            margin: 0;
            transform: none;
            height: auto;
            width: auto;
  
            svg {
              fill: rgba(154, 164, 178, 1);
              width: 22px;
              height: 22px;
              transition: all 0.3s ease-in-out 0s;
            }
  
            &::after {
              display: none;
            }
  
            &:hover {
              svg {
                fill: $secondary;
              }
            }
          }
  
          .swiper-pagination {
            position: initial;
            transform: none !important;
  
            .swiper-pagination-bullet {
              margin: 0 3px;
              background-color: #373737;
              opacity: 1;
  
              &.swiper-pagination-bullet-active {
                background-color: $secondary;
              }
            }
          }
        }
  
        figure {
          height: calc(100vh - (40px + 35px));
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 66px;
  
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
  
        .slick-dots {
          left: 50%;
          width: calc(100% - 80px);
          transform: translate(-50%, 0);
          bottom: 22px !important;
          line-height: 0;
  
          li {
            margin: 0 1px;
          }
        }
  
        .slick-arrow {
          bottom: 15px;
          width: 36px;
          height: 36px;
          transform: none;
          margin: 0;
          top: auto;
          display: flex !important;
          align-items: center;
          justify-content: center;
  
          &::before {
            content: "\2192";
            color: #000;
            font-size: 36px;
            font-family: initial;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 9;
          }
  
          &.slick-prev {
            left: 0;
  
            &::before {
              transform: scaleX(-1);
            }
          }
  
          &.slick-next {
            right: 0;
          }
        }
      }
    }
  }