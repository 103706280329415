@import "../../assets/theme/variable.scss";
.mn_brdcrmbs {
    background: rgba(128, 128, 128, 0.0705882353);
    .brdcrmb {
      &:hover {
        color: $primary;
      }
    }
  }
  .no_brdcrmbs {
    display: none;
  }