@import "../../assets/theme/variable.scss";
.search_bar {
    width: 100%;
    max-width: 850px;
    position: relative;
  
    .search_icon {
      width: auto;
      height: auto;
      margin: 10px;
      max-height: initial;
      color: #999;
      left: 10px;
    }
  
    input {
      background: $white;
    }
  }