@import "../../assets/theme/variable.scss";

.quick_view {
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;
  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
    svg {
      cursor: pointer;
      color: $c_black;
    }
  }
}
.quick_view_detail {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .dLeft {
    width: 48%;
    position: relative;

    .mgnify_icn {
      position: absolute;
      top: 15px;
      right: 15px;
      background: #820000;
      width: 35px;
      height: 35px;
      padding: 5px;
      border-radius: 5px;
      color: #fff;
    }

    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 20px;
    }
    @media (max-width: 600px) {
      margin-bottom: 70px;
    }
    .prdct_img_sc {
      width: 100% !important;
      position: relative;
      z-index: 999;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.0784313725);
      border-radius: 20px;

      > div {
        width: 500px;
        margin: 0 auto;
        overflow: hidden;
        height: 350px !important;
      }
      img {
        object-fit: contain;
        width: 100% !important;
        height: 100% !important;
        cursor: pointer;
      }
    }
    .magnifier_show {
      left: 680px;
      @media (max-width: 1400px) {
        left: 470px;
      }
      @media (max-width: 1200px) {
        left: 470px;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    .p_Imgs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 15px;
      flex-wrap: wrap;

      @media (max-width: 575px) {
        display: none;
      }

      figure {
        border-radius: 6px !important;
        overflow: hidden;
        height: 70px;
        width: 15%;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .dRyt {
    width: 49%;

    @media (max-width: 992px) {
      width: 100%;
    }

    .rightCard {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #00000014;
      border-radius: 10px;
      opacity: 1;
      min-height: 350px;
      max-height: 350px;
      overflow: auto;
      padding: 20px;
      @media (max-width: 575px) {
        display: block;
      }
      .mn_rightCard {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .auto_left {
          display: flex;
          // align-items: center;
          align-items: flex-end;
          flex-direction: column;
          justify-content: space-between;
          // margin-top: 20px;
        }
        .auto_ryt {
          max-width: 240px;
          h3 {
            font-size: 16px;
            color: #5d5d5d;
          }

          h2 {
            font-size: 20px;
            padding-top: 0px;

            @media (max-width: 575px) {
              font-size: 20px;
            }
          }

          .p2 {
            font-size: 16px;
            color: $gray;
            padding: 8px 0;
          }
        }
        .auto_left {
          .fig {
            display: flex;
            justify-content: flex-end;

            figure {
              margin-left: 13px;
              width: 32px;
              height: 32px;
              cursor: pointer;
              border: 1px solid $secondary;
              border-radius: 50%;
              padding: 5px;
            }
          }

          h2 {
            // font-size: 26px;
            font-size: 18px;
            color: $secondary;
            padding-top: 10px;

            @media (max-width: 600px) {
              font-size: 20px;
            }
          }
        }
      }
    }

    .rightCard2 {
      margin: 20px 0;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #00000014;
      border-radius: 10px;
      opacity: 1;
      padding: 20px;

      .card_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .cd_lft {
          display: flex;
          align-items: center;
          max-width: 78%;

          figure {
            width: 85px;
            min-width: 85px;
            height: 85px;
            background: #80808052;
            padding: 4px;
            border-radius: 100%;

            @media (max-width: 1200px) {
              width: 65px;
              min-width: 65px;
              height: 65px;
            }

            img {
              object-fit: cover;
              border-radius: 100%;
              cursor: pointer;
            }
          }

          .username {
            margin-left: 17px;

            h3 {
              font-size: 18px;
              color: $c_black;
              cursor: pointer;
            }

            h4 {
              font-size: 15px;
              padding-top: 2px;
              color: $c_black;
              font-weight: 400;
            }
          }
        }

        .cd_ryt {
          @media (max-width: 575px) {
            width: 100%;
            text-align: center;
            margin-top: 20px;
          }

          p {
            font-size: 16px;
            font-weight: bold;
            color: $c_black;
            cursor: pointer;

            &:hover {
              border-bottom: 1px solid $c_black;
              color: $primary;
            }
          }
        }
      }

      .card_btm {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        button {
          figure {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          .offr_img {
            img {
              filter: invert(0.9);
            }
          }

          width: 48%;
          border: 1px solid $primary;
          border-radius: 30px;
          font-size: 14px;
          text-transform: capitalize;
          color: $c_black;

          &:hover {
            background: $primary;
            color: $white;

            figure {
              img {
                filter: brightness(0) invert(1);
              }
            }
          }

          @media (max-width: 480px) {
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
