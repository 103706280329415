@import "/src/assets/theme/variable.scss";

body {
  background: white !important;
}

.container {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1400px) {
    max-width: 1170px;
    width: 90%;
  }
}

* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

img {
  width: 100%;
  height: 100%;
}

.cht_revrs {
  flex-direction: unset !important;

  @media (max-width: 767px) {
    .pRyt {
      padding: 15px !important;
    }

    .pRyt .main {
      padding: 0px !important;
    }
  }
}

// Common
.anchor_link {
  color: $primary;

  cursor: pointer;
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $white;
    background-color: $primary;
  }

  &.btn_danger {
    color: $white;
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_black;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  > .MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      > fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;

    .search {
      input.search-box {
        width: 93% !important;
      }
    }
  }

  :is(input, select, input.form-control) {
    color: $c_black;
    font-weight: 500;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    border-radius: 50px !important;
    border: 1px solid transparent;
    box-shadow: none !important;

    &:focus {
      border-color: $primary;
    }

    &::placeholder {
      color: $c_black;
      opacity: 0.6;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;

    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
  }
}

.form_btn {
  margin-top: 30px;
}

.search_bar .MuiInputBase-root {
  height: 50px;
}

.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.react-tel-input .selected-flag:focus:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.text_field.text_area {
  textarea {
    width: 100%;
    height: 134px;
    background-color: rgb(235, 235, 235) !important;
    border-radius: 20px !important;

    padding: 20px;
    border: 1px solid transparent !important;
    font-size: 14px;
    font-weight: 300;

    &:focus {
      border: 1px solid $primary !important;
    }
  }
}

.date-picker-container {
  margin-bottom: 15px;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;

    .datepicker {
      width: 100%;
      background-color: rgb(235 235 235) !important;
      color: #1d1d1d;
      font-weight: 500;
      height: 50px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 300;
      border-radius: 50px !important;
      border: 1px solid transparent;
      box-shadow: none !important;
      padding: 0 15px;
    }
  }

  .calendar-icon {
    position: absolute;
    top: 14px;
    right: 15px;

    svg {
      color: $secondary;
      cursor: pointer;
    }
  }
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 4px 0;
  font-weight: 400;
  padding: 0 !important;
}

.text_field {
  margin-bottom: 15px !important;

  input {
    background-color: rgb(235, 235, 235);
    border-radius: 50px;
  }
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.logut_flx {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  margin: 30px 0 16px;

  button {
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}

.logut_flx1 {
  margin-top: 20px;
}

.image {
  width: 80px;
  height: 80px;

  border-radius: 50%;
  margin: 20px 0;
  padding: 4px;
  outline: 1px solid $secondary !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  figure {
    width: 100%;
    height: 100%;
    background: $lightGrey;
    overflow: hidden;
    margin: auto;

    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  svg {
    position: absolute;
    bottom: -13px;
    background: $secondary;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    font-size: 21px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

button.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.text_field {
  .Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: $c_black !important;
    cursor: not-allowed;
  }
}

.srch_inpt > div {
  position: relative !important;
  padding: 0 !important;
  background: white !important;
  border-radius: 50px !important;
  padding-right: 14px !important;
}

.srch_inpt {
  input {
    &:focus {
      border-color: white !important;
    }
  }

  svg {
    cursor: pointer;
  }

  .cross_svg {
    border-radius: 50%;
    background: gray;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
}

.noData {
  margin: auto;

  figure {
    width: 100px;
    height: 100px;
    margin: auto;

    img {
      filter: brightness(1.7);
    }
  }

  h3 {
    font-size: 16px;
    color: $primary;
    padding-top: 5px;
    text-align: center;
  }
}

.see_heading {
  h3 {
    font-size: 33px;
    margin-bottom: 30px;
    font-weight: 500;
    color: $secondary;

    @media (max-width: 600px) {
      font-size: 30px;
    }

    @media (max-width: 420px) {
      font-size: 20px;
    }
  }
}

.top_mt {
  margin-top: 200px;
}

.boosterValidity {
  display: flex;
  align-items: center;
  gap: 10px;
  background: $secondary;
  color: white;
  padding: 7px;
  border-radius: 5px;
  margin-top: 20px;
}

.img_style {
  object-fit: cover !important;
}

.img_style2 {
  object-fit: contain !important;
}

.paymnt_nodata {
  height: 350px;
}

.pac-container.pac-logo {
  z-index: 99999;
}

.logo_img {
  height: 60px;
  width: 60px;

  @media (max-width: 600px) {
    height: 40px;
    width: 40px;
  }
}

.magnifier_show {
  left: 680px;

  @media (max-width: 1400px) {
    left: 600px;
  }

  @media (max-width: 1200px) {
    left: 560px;
  }

  @media (max-width: 992px) {
    display: none;
  }
}

.arabic_lang {
  direction: rtl;
}

.arabic_lang {
  .hdr .hdr_mn .hdr_top .hdr_ryt h4 {
    min-width: 155px;
  }

  .slr_btn2 {
    min-width: 110px !important;
  }

  .slr_btn {
    min-width: 125px !important;
  }

  .hdr .hdr_mn .hdr_top .hdr_srch {
    margin-left: unset;
    margin-right: 140px;
  }

  .vrfctn_dv2 .vrfctn_btn2 {
    padding-left: unset;
    padding-right: 4px;
  }

  .magnifier_show {
    right: 680px;

    @media (max-width: 1400px) {
      right: 600px;
    }

    @media (max-width: 1200px) {
      right: 560px;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .hdr .hdr_mn .hdr_top .hdr_ryt .users_dv svg {
    right: unset;
    left: 3px;
  }

  ul.chat li h5 {
    direction: ltr !important;
  }

  .product_srch ul li.main_li .li_ryt {
    margin-right: 10px;
    margin-left: 10px;
  }

  .cards_sec .mn_crds .crd_dtls figure {
    margin-right: 0;
    margin-left: 20px;
  }

  .react-tel-input .selected-flag {
    padding: 0 11px 0 0;
  }

  .react-tel-input .selected-flag .arrow {
    right: 29px;
  }

  input {
    text-align: right;
  }

  .react-tel-input input {
    padding-right: 50px;
  }

  .text_field {
    .eye_btn {
      left: 0;
      right: unset;
    }

    input {
      padding-right: 20px;
    }

    p {
      text-align: right;
    }
  }

  .make_an_offer .cross {
    left: 10px;
    right: unset;
  }

  .chat_foot {
    & > div {
      margin-left: 10px;
      margin-right: 0;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .flx_dv_prfl .hdr_btn {
    padding: 0 8px 0 16px;

    figure {
      margin-right: 0;
      margin-left: 6px;
    }
  }

  .heading .userDetail figure {
    margin-left: 10px;
    margin-right: 0;
  }

  .chat-div {
    padding-left: 10px;
    padding-right: 0;
  }

  .pagination {
    direction: ltr;
  }
}

.paymnt_nodata_add {
  height: 170px;
}

.add_dtls {
  background: #00c6ff45;
  padding: 14px 0 14px 10px !important;
}

// google translate

body > .skiptranslate {
  display: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  padding: 2px !important;
  height: 30px !important;
  width: 100% !important;
  min-width: 77px;
  border: white;
  border-radius: 10px;
}

.goog-te-gadget img {
  width: 50px;
}

.translationnnn {
  max-width: 140px;
  line-height: 0;
  margin: 0 20px 0 0px;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget:nth-child(2) {
  display: none;
  color: transparent !important;
  font-size: 0 !important;
}

.goog-te-gadget {
  color: transparent !important;
}

#google_translate_element img,
#google_translate_element a {
  display: none !important;
}

.mob_view_back {
  height: 30px;
  width: 30px;
  position: absolute;
  display: none;
  left: 8%;

  @media (max-width: 992px) {
    display: block;
  }
}

.docs_modal .modalBox_body .close_btn {
  color: #fff;
  min-width: 1px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: all 0.3s ease-in-out 0s;
  width: auto;
}

.docs_modal .modalBox_body > .MuiBox-root {
  height: 100vh;
  max-width: 80vw;
}

.docs_modal .modalBox_body > .MuiBox-root {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 8px 16px -6px #2d73e314, 0 6px 8px -6px #2d73e31f;
  margin: 0 auto;
  max-width: 90%;
  padding: 25px;
  width: 100%;
}

.mgnify_icn {
  position: absolute;
  top: 50%;
  right: 50%;
  cursor: pointer;
}

.notification_btn {
  flex-direction: column !important;

  &:hover {
    background-color: transparent !important;
  }
}

.main_listing_sctn {
  min-height: 280px;
}

.react-tel-input .country-list .search-box {
  height: 40px !important;
  width: 90% !important;
}

.follow_click {
  cursor: pointer;
}

.selected {
  background: rgba(128, 128, 128, 0.1019607843);
}

.see_more {
  color: $primary !important;
}

h2.quickproduct_name {
  text-align: center;
  margin-bottom: 30px;
  color: $secondary;
}

.slr_btn2 {
  min-width: 170px !important;
}

.slr_btn {
  min-width: 140px !important;
}

.quic_vw_ac {
  &:first-child {
    margin-bottom: 20px;
  }

  .MuiAccordionSummary-gutters {
    background: $primary;
    color: white;
  }

  svg {
    color: white;
  }

  .MuiAccordionDetails-root:last-child {
    min-height: 170px;
    max-height: 170px;
    overflow-y: auto;
  }
}

.prdct_crd_nar {
  width: calc(100% / 2 - 10px * 1 / 2);
  height: 190px;
}

.prdct_crd_nar_flx {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.cat_shim {
  // display: flex;
  // gap: 35px;
  padding: 0px 10px;
  margin-bottom: 40px;
  padding-top: 80px;
}

.sc_Ryt {
  .prdct_hdngs {
    &:has(h2:empty) {
      display: none;
    }
  }

  .prodcts_sc {
    &.container {
      padding-top: 0;
      width: 100%;
    }
  }

  .manufacture_sc {
    margin: 20px 0;

    .slick-next {
      right: 6px;
    }

    .slick-prev {
      left: 6px;
    }

    .slick-list {
      @media screen and (max-width: 1199px) {
        margin-inline: -5px;
      }
    }

    .slick-slide {
      @media screen and (max-width: 1199px) {
        margin-inline: 5px;
      }
    }

    .manufacture_single {
      border-radius: 5px;

      &::before {
        opacity: 0.7;
      }

      figure {
        aspect-ratio: 1 / 0.68;

        @media screen and (max-width: 1199px) {
          aspect-ratio: 1 / 0.8;
        }

        @media screen and (max-width: 991px) {
          aspect-ratio: 1 / 1;
        }

        @media screen and (max-width: 767px) {
          aspect-ratio: initial;
          height: 300px;
        }
      }

      .inner_content {
        width: 100%;
        padding: 20px;
        text-align: center;

        h5 {
          margin-bottom: 8px;
          text-shadow: none;

          @media screen and (max-width: 1199px) {
            font-size: 22px;
          }
        }

        p {
          font-size: 13px;
          letter-spacing: -0.13px;
          margin-bottom: 8px;
          color: #fff;
        }

        a {
          color: #fff;
          font-size: 13px;
          text-decoration: underline;
          letter-spacing: 0.2px;
          text-underline-offset: 2px;
        }
      }
    }
  }
}

.payment_modl {
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;

  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;

    svg {
      cursor: pointer;
      color: $c_black;
    }
  }

  .dbl {
    display: flex;
    justify-content: space-between;
    width: 93%;
  }

  h2 {
    font-size: 24px;
    color: #000;
    font-weight: 500;
  }

  h5 {
    font-size: 15px;
    font-weight: 300;
    color: #6a6a6a;
    padding: 20px 0;

    span {
      color: $secondary;
    }
  }

  .custom_label2 {
    font-size: 15px;
    font-weight: 300;
    color: #6a6a6a;
  }

  .paymnt_modl_txtfld {
    // border-bottom: 1.5px solid #00000012 !important;

    input {
      padding: 0 !important;
      border-radius: 0 !important;
      color: black;
      font-weight: 600;
      height: 50px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 500;
      border-bottom: 1.5px solid #00000012 !important;

      &:focus {
        border: none;
        border-bottom: 1.5px solid $primary !important;
      }

      &:focus {
        border: none;
        border-bottom: 1.5px solid $primary !important;
      }
    }
    p {
      font-size: 14px !important;
      color: red !important;
      border-bottom: none !important;
      line-height: 18px !important;
    }

    figure {
      width: 50px;
      height: 45px;

      img {
        object-fit: contain;
      }
    }

    .cvv_img {
      height: 20px;
      width: 20px;
    }
  }
}
.add_crd {
  padding-top: 20px;
  display: flex;
  width: fit-content;
  cursor: pointer;

  figure {
    width: 50px;
    height: 30px;
    margin-right: 10px;
  }

  .add_details {
    figure {
      width: 50px;
      height: 30px;

      img {
        object-fit: contain;
      }
    }

    h4 {
      color: $secondary;
      font-size: 16px;
      font-weight: 400;
    }

    p {
      color: $gray !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      padding-top: 4px !important;
    }

    .paymnt_cards {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 6px;

      figure {
        width: 40px;
        height: 20px;
        margin-right: 10px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}

.fltr_btn_sc {
  display: flex;
  justify-content: flex-end;

  .fltr_btnn {
    background: $primary;
    color: $white;
    border-radius: 30px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-left: auto;
    border: 1px solid $primary;
    cursor: pointer;
    &:hover {
      background: white;
      color: $primary;
      border: 1px solid $primary;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.wrapCls {
  min-height: calc(100vh - 557px);
  padding-bottom: 60px;
  padding-top: 60px;
}
