@import "../../assets/theme/variable.scss";

.blog_crd {
  a {
    text-decoration: none !important;

    span {
      text-decoration: underline !important;
    }
  }
}

.blog_crd {
  padding: 15px;
  width: calc(100% / 3 - (20px * 2 / 3));
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 10px;

  @media screen and (max-width: 991px) {
    padding: 10px 10px 12px;
    border-radius: 5px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  figure {
    height: 220px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin: 0;

    @media screen and (max-width: 991px) {
      height: 150px;
      border-radius: 5px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-size: 18px;
    color: $c_black;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }

  p {
    font-size: 14px;
    color: #5d5d5d;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .blg_dt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0 12px;
    gap: 10px;

    @media screen and (max-width: 991px) {
      padding: 15px 0 10px;
    }

    p {
      margin-bottom: 0;

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }

    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 600;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }

  a {
    font-size: 12px;
    text-decoration: underline;
    color: #000;

    &:hover {
      color: $secondary;
    }
  }
}
