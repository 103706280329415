@import "../assets/theme/variable.scss";

//Product Card

.slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prodcts_sc {
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background: #fff;
  }
  .slick-prev,
  .slick-next {
    border-radius: 50%;
    border: 1px solid #ddd;
    background: #fff;
    z-index: 9;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #000;
      border-left: 2px solid #000;
      display: inline-block;
      transform: rotate(315deg);
      color: #000;
    }
    @media (max-width: 575px) {
      right: -12px !important;
    }
  }
  .slick-next::before {
    transform: rotate(135deg);
    right: 15px;
  }
  .slick-prev::before {
    left: 15px;
  }
  .slick-prev {
    @media (max-width: 575px) {
      left: -12px !important;
    }
  }
}
